import axios from 'axios';
import {API_BASE_URL} from 'configs/AppConfig';
import {AUTH_TOKEN} from 'constants/AuthConstant';
import {notification} from 'antd';
import {
    InvalidCurrentPassword,
    InvalidDate,
    InvalidEmail,
    InvalidNewPassword,
    InvalidUsername, LockedUser
} from "../constants/error-messages/ApiConstant";
import {signOut} from "../store/slices/authSlice";

const unauthorizedCode = [400, 401, 403, 404, 500, 508]

const service = axios.create({
    baseURL: API_BASE_URL,
    timeout: 360000
})

// Config
const TOKEN_PAYLOAD_KEY = 'authorization'

// API Request interceptor
service.interceptors.request.use(config => {
    const jwtToken = localStorage.getItem(AUTH_TOKEN) || null;
    if (jwtToken) {
        config.headers[TOKEN_PAYLOAD_KEY] = "Bearer " + jwtToken
    }
    if (config.url.includes("downloadfile")) {
        config['responseType'] = "blob";
    } else if (config.url.includes("upload")) {
        config.headers['Content-Type'] = 'multipart/form-data'
    }
    return config
}, error => {
    // Do something with request error here
    notification.error({
        message: 'Errore'
    })
    Promise.reject(error)
})

// API respone interceptor
service.interceptors.response.use((response) => {

    return response.data
}, (error) => {
    let notificationParam = {
        message: 'Errore Generico'
    }

    let description = ''
    console.log("response",error.response)

    console.log("message",error.response.data.error.message)
    switch (error.response?.data?.error?.message) {
        case 'Invalid date':
            description = InvalidDate
            break;
        case 'The provided current password is invalid':
            description = InvalidCurrentPassword
            break;
        case 'password must be at least 6 characters':
            description = InvalidNewPassword
            break;
        case 'Email already taken':
            description = InvalidEmail
            break;
        case 'This attribute must be unique':
            description = InvalidUsername
            break;
        case 'Invalid credentials':
            description = LockedUser;
            signOut();
            break;
        default:
            description = error.response.data.error.message + ". Se il problema persiste, contattare l'amministratore del sistema!"
    }
    //GESTIONE DEDICATA ALLA CRONOLOGIA
    if(error?.response?.config?.url?.includes("cronologia")){
        description = "Il paziente inizialmente collegato all'appuntamento non è più presente sulla piattaforma o non è mai stato associato (nota)."
        // PREPARAZIONE MESSAGGIO DI NOTIFICA IN ALTO A DESTRA
        if (unauthorizedCode.includes(error.response?.status)) {
            notificationParam.message = 'Attenzione'
            notificationParam.description = description
        }
        notification.warning(notificationParam)
        return Promise.reject(error);
    }
    notificationParam.description = description

    notification.error(notificationParam)
    return Promise.reject(error);
});

export default service