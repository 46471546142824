import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {PAZIENTI} from 'configs/AppConfig'
import StrapiService from "../../services/StrapiService";
import moment from "moment";
import {errorMessage} from "../../utils/messages";
import {PAZIENTI_ERROR, PAZIENTI_LOADED, PAZIENTI_MAP_VALUE, PAZIENTI_VALUE} from "../../constants/AuthConstant";
import PazientiService from "../../services/PazientiService";

export const initialState = PAZIENTI


// Funzione per ottenere una lista unica di studi di riferimento per ID
function getUniqueStudiosById(data) {
    const groupedById = {};

    // Raggruppa gli elementi per ID
    data.forEach(entry => {
        if (!groupedById[entry.id]) {
            groupedById[entry.id] = {...entry};
            groupedById[entry.id].studio_di_riferimento = [];
        }
        groupedById[entry.id].studio_di_riferimento.push(entry.studio_di_riferimento);
    });

    // Crea un array con gli oggetti raggruppati per ID e studi di riferimento unici
    const result = Object.values(groupedById).map(entry => ({
        ...entry,
        studio_di_riferimento: [...new Set(entry.studio_di_riferimento)]
    }));

    return result;
}


function trovaProssimoAppuntamento(cronologia) {
    // Filtra gli elementi con tipologia "Appuntamento"
    // const appuntamenti = cronologia.filter(item => item.tipologia === "Appuntamento");
    //
    // // Trova l'appuntamento con la data più recente
    // let ultimoAppuntamento = null;
    // let ultimaData = new Date(0); // Inizializzata a una data molto vecchia
    //
    // appuntamenti.forEach(item => {
    //     const dataAppuntamento = new Date(item.data);
    //     if (dataAppuntamento > ultimaData) {
    //         ultimaData = dataAppuntamento;
    //         ultimoAppuntamento = item;
    //     }
    // });
    //
    // return ultimoAppuntamento?.data ? moment(ultimoAppuntamento.data).format("DD-MM-YYYY") : 'N.D';


    const oggi = new Date(); // Data odierna

    const appuntamenti = cronologia.map(cron => ({
        data: cron.data,
        stato: cron.stato,
        tipologia: cron.tipologia,
        tipo: "appuntamento"
    }));

    const appuntamentiFiltrati = appuntamenti
        .filter(item => new Date(item.data) > oggi
            && (item.stato !== "annullato" && item.stato !== "APPUNTAMENTO MANCATO")
            && (item.tipologia === "Appuntamento"));

    // Filtrare gli oggetti con data futura
    var oggettiFuturi = appuntamentiFiltrati.filter(function (oggetto) {
        return new Date(oggetto.data) > oggi;
    });

    // Ordinare gli oggetti per data crescente
    oggettiFuturi.sort(function (a, b) {
        return new Date(a.data) - new Date(b.data);
    });

    // Ottenere il primo oggetto nella lista ordinata (quello con la data più vicina)
    var prossimoAppuntamento = oggettiFuturi.length === 0 ? 'N.D' : moment(oggettiFuturi[0].data).format('DD-MM-YYYY [alle ore] HH:mm');

    return prossimoAppuntamento;

}

export const loadPazienti = createAsyncThunk('carica-pazienti', async (data, {rejectWithValue, getState}) => {
    console.log("[loadPazienti]");

    const roles = getState().auth.binding_roles.map(role => role.id)

    try {
        let queryString = ""
        for (let i = 0; i < roles.length; i++) {
            queryString += "&filters[binding_roles][id]=" + roles[i]
        }
        //api/stores?&pagination[limit]=10000populate=*&filters[binding_roles][id]=5
        let pazienti = []
        let result = []
        if (queryString.length !== 0) {

            //OLD REQUEST
            //const response = await StrapiService.get('api/stores?&pagination[limit]=-1&populate=patients' + queryString);
            //NEW REQUEST
            const response = await StrapiService.get('api/stores?&pagination[limit]=-1&' + queryString + '&populate[patients][fields][0]=nome&populate[patients][fields][1]=cognome&populate[patients][fields][2]=data_di_nascita&populate[patients][fields][3]=citta&populate[patients][fields][4]=citta_di_residenza&populate[patients][fields][5]=tipo_provenienza&populate[patients][fields][6]=nome_provenienza&populate[patients][fields][7]=stato_paziente&populate[patients][fields][8]=tipo_paziente&populate[patients][fields][9]=provenienza&populate[patients][fields][10]=cellulare&populate[patients][fields][11]=studio_di_riferimento&populate[patients][fields][12]=anno_di_vendita&populate[patients][fields][13]=cronologia');
            for (let i = 0; i < response.data.length; i++) {
                for (let j = 0; j < response.data[i].attributes.patients.data.length; j++) {
                    let nomeStore = response.data[i].attributes.name;
                    let paziente = response.data[i].attributes.patients.data[j];
                    let ultimoAppuntamento = trovaProssimoAppuntamento(paziente.attributes.cronologia)

                    pazienti.push({
                        id: paziente.id,
                        nome: paziente.attributes.nome,
                        cognome: paziente.attributes.cognome,
                        data_di_nascita: paziente.attributes.data_di_nascita == null ? "N.D" : moment(paziente.attributes.data_di_nascita).format("DD-MM-YYYY"),
                        citta: paziente.attributes.citta == null ? "N.D" : paziente.attributes.citta,
                        citta_di_residenza: paziente.attributes.citta_di_residenza == null ? "N.D" : paziente.attributes.citta_di_residenza,
                        tipo_provenienza: paziente.attributes.tipo_provenienza,
                        nome_provenienza: paziente.attributes.nome_provenienza,
                        stato_paziente: paziente.attributes.stato_paziente?.split(",") ? paziente.attributes.stato_paziente.split(",").sort() : [],
                        tipo_paziente: paziente.attributes.tipo_paziente,
                        provenienza: paziente.attributes.tipo_provenienza + " " + paziente.attributes.nome_provenienza,
                        cellulare: paziente.attributes.cellulare,
                        studio_di_riferimento: nomeStore,
                        ultimo_appuntamento: ultimoAppuntamento,
                        anno_di_vendita: paziente.attributes.anno_di_vendita?.split(",") ? paziente.attributes.anno_di_vendita.split(",").map(Number).sort(function (a, b) {
                            return a - b;
                        }) : []
                    })
                }
            }
            result = getUniqueStudiosById(pazienti);
        }

        localStorage.setItem(PAZIENTI_VALUE, JSON.stringify(result))
        // console.table(result)
        return result

    } catch (err) {
        console.error("err", err)
        let message = (
            <div>
                Qualcosa è andato storto nel recuperare i pazienti. Riprovare!
                <br/>
                Se il problema persiste, contattare l' assistenza.
            </div>
        );

        errorMessage(message)
        return rejectWithValue('ERRORE')
    }
})


export const deletePaziente = createAsyncThunk('cancella-paziente', async (id, {rejectWithValue, getState}) => {
    console.log("[deletePaziente]");
    try {
        let currentState = getState().pazienti.pazienti.map((item) => ({
            ...item,
        }))
        await PazientiService.deletePatient(id);
        const indexobj = currentState.findIndex((element) => element.id === id);
        currentState.splice(indexobj, 1)
        return currentState
    } catch (err) {
        console.error("err", err)
        let message = (
            <div>
                Qualcosa è andato storto nel cancellare il paziente. Riprovare!
                <br/>
                Se il problema persiste, contattare l' assistenza.
            </div>
        );

        errorMessage(message)
        return rejectWithValue('ERRORE')
    }
})

export const editPatient = createAsyncThunk('modifica-paziente', async (input, {rejectWithValue, getState}) => {
    try {
        console.log("[editPatient]", input.id)
        const id = input.id
        let currentState = getState().pazienti.pazienti.map((item) => ({
            ...item,
        }))
        const indexobj = currentState.findIndex((element) => element.id === parseInt(id));
        const patient = input.updatedFields.attributes

        let updatedPatient = {
            id: id,
            nome: patient.nome,
            cognome: patient.cognome,
            data_di_nascita: patient.data_di_nascita == null ? "N.D" : moment(patient.data_di_nascita).format("DD-MM-YYYY"),
            citta: patient.citta == null ? "N.D" : patient.citta,
            citta_di_residenza: patient.citta_di_residenza == null ? "N.D" : patient.citta_di_residenza,
            tipo_provenienza: patient.tipo_provenienza,
            nome_provenienza: patient.nome_provenienza,
            stato_paziente: patient.stato_paziente?.split(",") ? patient.stato_paziente.split(",").sort() : [],
            tipo_paziente: patient.tipo_paziente,
            provenienza: patient.tipo_provenienza + " " + patient.nome_provenienza,
            cellulare: patient.cellulare,
            studio_di_riferimento: patient.stores.data.map((store) => store.attributes.name),
            ultimo_appuntamento: trovaProssimoAppuntamento(patient.cronologia),
            anno_di_vendita: patient.anno_di_vendita?.split(",") ? patient.anno_di_vendita.split(",").map(Number).sort(function (a, b) {
                return a - b;
            }) : []
        }
        currentState[indexobj] = updatedPatient
        localStorage.setItem(PAZIENTI_VALUE, JSON.stringify(currentState))
        return currentState
    } catch (err) {
        console.error("err", err)
        let message = (
            <div>
                Non è stato possibile modificare l'evento.
                <br/>
                Se il problema persiste, contattare l' assistenza.
            </div>
        );

        errorMessage(message)
        return rejectWithValue('ERRORE')
    }
});

export const addNewPatient = createAsyncThunk('aggiungi-paziente', async ({formData, id}, {
    rejectWithValue,
    getState
}) => {
    try {
        const currentState = getState().pazienti.pazienti;
        localStorage.setItem(PAZIENTI_VALUE, JSON.stringify([...currentState, ...[setAddPatient(formData, id)]]))
        return [...currentState, ...[setAddPatient(formData, id)]];
    } catch (err) {
        console.error("err", err)
        let message = (
            <div>
                Non è stato possibile aggiungere il paziente.
                <br/>
                Se il problema persiste, contattare l' assistenza.
            </div>
        );

        errorMessage(message)
        return rejectWithValue('ERRORE')
    }
});
export const editProssimoApputamento = createAsyncThunk('modifica-paziente-prossimo-apputamento', async (input, {rejectWithValue, getState}) => {
    try {
        const id = input.id
        const cronologia = input.cronologia
        let currentState = getState().pazienti.pazienti.map((item) => ({
            ...item,
        }))
        const indexobj = currentState.findIndex((element) => element.id === parseInt(id));
        const patient = currentState[indexobj]
        let updatedPatient = {
            ...patient,
            ultimo_appuntamento: trovaProssimoAppuntamento(cronologia),
        }
        currentState[indexobj] = updatedPatient
        localStorage.setItem(PAZIENTI_VALUE, JSON.stringify(currentState))
        return currentState
    } catch (err) {
        console.error("err", err)
        let message = (
            <div>
                Non è stato possibile modificare l'evento.
                <br/>
                Se il problema persiste, contattare l' assistenza.
            </div>
        );

        errorMessage(message)
        return rejectWithValue('ERRORE')
    }
});
const setAddPatient = (patient, id) => {
    return {
        id: id,
        nome: patient.nome,
        cognome: patient.cognome,
        data_di_nascita: patient.data_di_nascita == null ? "N.D" : moment(patient.data_di_nascita).format("DD-MM-YYYY"),
        citta: patient.citta == null ? "N.D" : patient.citta,
        citta_di_residenza: patient.citta_di_residenza == null ? "N.D" : patient.citta_di_residenza,
        tipo_provenienza: patient.tipo_provenienza,
        nome_provenienza: patient.nome_provenienza,
        stato_paziente: [],
        anno_di_vendita: [],
        provenienza: patient.tipo_provenienza + " " + patient.nome_provenienza,
        cellulare: patient.cellulare,
        studio_di_riferimento: patient.studio_di_riferimento.split(","),
        ultimo_appuntamento: "N.D"
    }
}

export const pazientiSlice = createSlice({
    name: 'pazienti', initialState, reducers: {
        pazientiLoaded: (state, action) => {
            //console.log("state", state)
            state.loading = false
            state.pazienti = action.payload;
        },
        showLoading: (state) => {
            state.loading = true
        },
        updatePaziente: (state, action) => {
            const {id, updatedFields} = action.payload;
            state.pazienti = state.pazienti.map((paziente) => {
                if (paziente.id == id) {
                    const campiDaAggiornare = {};
                    for (const campo in updatedFields) {
                        if (campo in paziente) {
                            // questo if è importante, serve per modificare solo le chiavi del json che fanno parte della listaPazienti del redux!
                            if (updatedFields[campo] != null) {
                                if (campo !== "data_di_nascita") {
                                    campiDaAggiornare[campo] = updatedFields[campo];
                                } else {
                                    campiDaAggiornare[campo] = moment(updatedFields[campo]).format("DD-MM-YYYY");
                                }
                            } else {
                                campiDaAggiornare[campo] = "N.D";
                            }
                        }
                    }

                    return {
                        ...paziente,
                        ...campiDaAggiornare, // Combina i campi esistenti con i campi aggiornati
                    };
                }
                return paziente;
            });
        },
    }, extraReducers: (builder) => {
        builder
            .addCase(loadPazienti.pending, (state) => {
                state.loadingPazienti = true
                state.loadedPazienti = false
                state.errorPazienti = false
            })
            .addCase(loadPazienti.fulfilled, (state, action) => {
                state.loadingPazienti = false
                state.pazienti = action.payload

                localStorage.setItem(PAZIENTI_LOADED, true)
                localStorage.setItem(PAZIENTI_ERROR, false)
                const pazientiMap = action.payload.reduce((acc, patient) => {
                    acc[patient.id] = patient;
                    return acc;
                }, {});
                state.pazientiMap = pazientiMap
                localStorage.setItem(PAZIENTI_MAP_VALUE, JSON.stringify(pazientiMap))

                state.loadedPazienti = true
                state.errorPazienti = false
            })
            .addCase(loadPazienti.rejected, (state, action) => {
                state.loadingPazienti = false
                state.pazienti = []
                state.pazientiMap = {}

                localStorage.setItem(PAZIENTI_LOADED, true)
                localStorage.setItem(PAZIENTI_ERROR, true)
                localStorage.removeItem(PAZIENTI_VALUE)
                localStorage.removeItem(PAZIENTI_MAP_VALUE)
                state.loadedPazienti = true
                state.errorPazienti = false
            })
            .addCase(deletePaziente.pending, (state) => {
                state.loadingDeletePazienti = true
                state.loadedDeletePazienti = false
                state.errorDeletePazienti = false
            })
            .addCase(deletePaziente.fulfilled, (state, action) => {
                state.loadingDeletePazienti = false
                state.pazienti = action.payload
                localStorage.setItem(PAZIENTI_VALUE, JSON.stringify(action.payload))

                const pazientiMap = action.payload.reduce((acc, patient) => {
                    acc[patient.id] = patient;
                    return acc;
                }, {});
                state.pazientiMap = pazientiMap
                localStorage.setItem(PAZIENTI_MAP_VALUE, JSON.stringify(pazientiMap))

                state.loadedDeletePazienti = true
                state.errorDeletePazienti = false
            })
            .addCase(deletePaziente.rejected, (state, action) => {
                state.loadingDeletePazienti = false
                state.loadedDeletePazienti = true
                state.errorDeletePazienti = false
            })
            .addCase(editPatient.fulfilled, (state, action) => {
                state.pazienti = action.payload
                const pazientiMap = action.payload.reduce((acc, patient) => {
                    acc[patient.id] = patient;
                    return acc;
                }, {});
                state.pazientiMap = pazientiMap
                localStorage.setItem(PAZIENTI_MAP_VALUE, JSON.stringify(pazientiMap))
            })
            .addCase(editProssimoApputamento.fulfilled, (state, action) => {
                state.pazienti = action.payload
                const pazientiMap = action.payload.reduce((acc, patient) => {
                    acc[patient.id] = patient;
                    return acc;
                }, {});
                state.pazientiMap = pazientiMap
                localStorage.setItem(PAZIENTI_MAP_VALUE, JSON.stringify(pazientiMap))
            })
            .addCase(addNewPatient.fulfilled, (state, action) => {
                state.pazienti = action.payload
                const pazientiMap = action.payload.reduce((acc, patient) => {
                    acc[patient.id] = patient;
                    return acc;
                }, {});
                state.pazientiMap = pazientiMap
                localStorage.setItem(PAZIENTI_MAP_VALUE, JSON.stringify(pazientiMap))
            })

    }
})

export const {
    updatePaziente,
} = pazientiSlice.actions

export default pazientiSlice.reducer;