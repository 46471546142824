import {createAction, createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {errorMessage} from "../../utils/messages";
import CalendarService from "../../services/CalendarService";
import moment from "moment";
import "moment/locale/it";
import store from "../index";
import {getIndexEnd, getIndexStart} from 'views/app-views/agenda/sub-component/config';
import SchedaPazienteService from "../../services/SchedaPazienteService";
import {editProssimoApputamento} from "./pazientiSlice";

export const initialState = {
    //eventi
    events: [],
    //stati dicaricmento degli eventi iniziali o quando supero il range
    loadingEvents: false,
    loadedEvents: false,
    errorEvents: false,
    //RIFERIMENTI CHE MI MANTENGONO AGGIORNATO GLI l'INIZIO E LA FINE DI DOVE HO GIA SCARICATO
    indexStart: getIndexStart(),
    indexEnd: getIndexEnd(),
    //stati di carimento quando modifico aggiungo evento
    loadingAddEditEvents: false,
    loadedAddEditEvents: false,
    errorAddEditEvents: false,
}

export const loadEvents = createAsyncThunk('carica-eventi', async (input, {rejectWithValue, getState}) => {

    console.log("[loadEvents]")
    try {

        // console.log("[loadEvents]", getState())
        const firstTime = input.firstTime

        let start = null;
        let end = null;
        if (firstTime) {
            store.dispatch(CalendarSlice.actions.resetState());
            store.dispatch(setIndexStart(getIndexStart()));
            store.dispatch(setIndexEnd(getIndexEnd()));
            start = getState().events.indexStart.format('YYYY-MM-DD HH:mm')
            end = getState().events.indexEnd.format('YYYY-MM-DD HH:mm')
        } else {

            start = input.start.format('YYYY-MM-DD HH:mm')
            end = input.end.format('YYYY-MM-DD HH:mm')
        }


        let eventiPresenti = getState().events.events
        const puntivendita = getState().puntiVendita.puntiVendita

        /*const start = input.start.format('YYYY-MM-DD HH:mm')
        const end = input.end.format('YYYY-MM-DD HH:mm')*/

        //OTTENERE GLI UTENTI
        const response = await CalendarService.getEvents(puntivendita, start, end);
        const data = response.data

        let newEvents = data.map(event => (setEvent(event)))
        console.table(eventiPresenti, newEvents)
        return [...eventiPresenti, ...newEvents]

    } catch (err) {
        console.error("err", err)
        let message = (
            <div>
                Qualcosa è andato storto nel recuperare gli eventi. Riprovare!
                <br/>
                Se il problema persiste, contattare l' assistenza.
            </div>
        );

        errorMessage(message)
        return rejectWithValue('ERRORE')
    }
})

export const addNewEvent = createAsyncThunk('aggiungi-eventi', async (body, {rejectWithValue, getState}) => {
    try {


        const currentState = getState().events.events;


        if ((typeof body.data.start.format === 'function') && (getState().events.indexEnd.isBefore(moment(body.data.start.format())) || getState().events.indexStart.isAfter(moment(body.data.end.format())))) {

            console.log("PRIMO IF")

            const response = await CalendarService.addEvent(body);

            let cronologia = {}
            cronologia.data = body.data.start;
            cronologia.note = body.data.note;
            cronologia.referente = JSON.parse(localStorage.getItem("info_user"))?.username;
            cronologia.tipologia = "Appuntamento";
            cronologia.id = response.data.id;
            cronologia.stato = body.data.stato;
            console.log("CONTROLLO",body.data.tipo!=="nota" && body.data.isChecked!==true)
            if(body.data.tipo!=="nota" && body.data.isChecked!==true){
                const cronologiaList = await SchedaPazienteService.getCronologia(body.data.patient)
                let bodyCronologia = {
                    "data": {
                        "cronologia": [...cronologiaList.data.attributes.cronologia, cronologia]
                    }
                }
                //EFFETTUO CHIAMATA
                await SchedaPazienteService.aggiornaCronologia(body.data.patient, bodyCronologia);
                await store.dispatch(editProssimoApputamento({id:body.data.patient,cronologia:bodyCronologia.data.cronologia}))
            }
            //COSTRUISCO BODY RICHIESTA

            return [...currentState]
        } else {
            console.log("SECONDO IF")

            const response = await CalendarService.addEvent(body);

            let cronologia = {}
            cronologia.data = body.data.start;
            cronologia.note = body.data.note;
            cronologia.referente = JSON.parse(localStorage.getItem("info_user"))?.username;
            cronologia.tipologia = "Appuntamento";
            cronologia.id = response.data.id;
            cronologia.stato = body.data.stato;
            console.log("CONTROLLO",body.data.tipo!=="nota" && body.data.isChecked!==true)
            if(body.data.tipo!=="nota" && body.data.isChecked!==true ) {
                console.log("AGGIORNAMENTO CRONOLGOIA")
                const cronologiaList = await SchedaPazienteService.getCronologia(body.data.patient)
                //COSTRUISCO BODY RICHIESTA
                let bodyCronologia = {
                    "data": {
                        "cronologia": [...cronologiaList.data.attributes.cronologia, cronologia]
                    }
                }
                //EFFETTUO CHIAMATA
                await SchedaPazienteService.aggiornaCronologia(body.data.patient, bodyCronologia);
                await store.dispatch(editProssimoApputamento({id:body.data.patient,cronologia:bodyCronologia.data.cronologia}))
            }

            return [...currentState, ...[setEvent(response.data)]];
        }
    } catch (err) {
        console.error("err", err)
        let message = (
            <div>
                Non è stato possibile aggiungere l'evento.
                <br/>
                Se il problema persiste, contattare l' assistenza.
            </div>
        );

        errorMessage(message)
        return rejectWithValue('ERRORE')
    }
});

export const editEvent = createAsyncThunk('modifica-eventi', async (input, {rejectWithValue, getState}) => {
    try {
        let currentState = getState().events.events.map((item) => ({
            ...item,
        }))
        if ((typeof input.body.data.start.format === 'function') && (getState().events.indexEnd.isBefore(moment(input.body.data.start.format())) || getState().events.indexStart.isAfter(moment(input.body.data.end.format())))) {
            await CalendarService.editEvent(input.id, input.body);
            try {
                //(nota -> faccio il try catch perchè non potrebbe esistere il paziente e quindi crashava l'applicazione
                //CRONOLOGIA
                if (input.body.data.tipo !== "nota" && input.body.isChecked!==true && input.paziente!==undefined) {
                    let patientId=input.body.data.patient
                    if(input.paziente!==-1){
                        patientId=parseInt(input.paziente.trim())
                    }
                    const cronologiaList = await SchedaPazienteService.getCronologia(patientId)
                    let index = cronologiaList.data.attributes.cronologia.findIndex(cronologia => cronologia.id === input.id && cronologia.tipologia === "Appuntamento");
                    //aggiornamento
                    let updatedData = [...cronologiaList.data.attributes.cronologia]
                    let cronologia = {}
                    console.log("input",input)
                    cronologia.data = input.body.data.start;
                    cronologia.note = input.body.data.note;
                    cronologia.referente = JSON.parse(localStorage.getItem("info_user"))?.username;
                    cronologia.tipologia = "Appuntamento";
                    cronologia.id = input.id;
                    cronologia.stato = input?.body?.data?.stato!==undefined ? input.body.data.stato:updatedData[index].stato;
                    updatedData[index] = cronologia;
                    //COSTRUISCO BODY RICHIESTA
                    let body = {
                        "data": {
                            "cronologia": updatedData
                        }
                    }
                    //EFFETTUO CHIAMATA
                    await SchedaPazienteService.aggiornaCronologia(patientId, body);
                    await store.dispatch(editProssimoApputamento({id:patientId,cronologia:body.data.cronologia}))
                }
            }catch (e) {
                console.error(e)
            }

            const indexobj = currentState.findIndex((element) => element.id === input.id);
            currentState.splice(indexobj, 1)
            return currentState
        } else {

            const response = await CalendarService.editEvent(input.id, input.body);

            //(nota -> faccio il try catch perchè non potrebbe esistere il paziente e quindi crashava l'applicazione
            try {
                //CRONOLOGIA
                console.log("input.body.data.tipo")
                if (input.body.data.tipo !== "nota" && input.body.isChecked!==true && input.paziente!==undefined) {
                    let patientId=input.body.data.patient
                    if(input.paziente!==-1){
                        patientId=parseInt(input.paziente.trim())
                    }
                    const cronologiaList = await SchedaPazienteService.getCronologia(patientId)
                    let index = cronologiaList.data.attributes.cronologia.findIndex(cronologia => cronologia.id === input.id && cronologia.tipologia === "Appuntamento");
                    //aggiornamento
                    let updatedData = [...cronologiaList.data.attributes.cronologia]
                    let cronologia = {}

                    cronologia.data = input.body.data.start;
                    cronologia.note = input.body.data.note;
                    cronologia.referente = JSON.parse(localStorage.getItem("info_user"))?.username;
                    cronologia.tipologia = "Appuntamento";
                    cronologia.id = input.id;
                    cronologia.stato = input?.body?.data?.stato!==undefined ? input.body.data.stato:updatedData[index].stato;
                    updatedData[index] = cronologia;
                    //COSTRUISCO BODY RICHIESTA
                    let body = {
                        "data": {
                            "cronologia": updatedData
                        }
                    }
                    //EFFETTUO CHIAMATA
                    await SchedaPazienteService.aggiornaCronologia(patientId, body);
                    await store.dispatch(editProssimoApputamento({id:patientId,cronologia:body.data.cronologia}))
                }
            }catch (e) {
                console.error(e)
            }

            const indexobj = currentState.findIndex((element) => element.id === input.id);
            currentState[indexobj] = setEvent(response.data);
            return currentState
        }
    } catch (err) {
        console.error("err", err)
        let message = (
            <div>
                Non è stato possibile modificare l'evento.
                <br/>
                Se il problema persiste, contattare l' assistenza.
            </div>
        );

        errorMessage(message)
        return rejectWithValue('ERRORE')
    }
});

export const editEventState = createAsyncThunk('modifica-stato-eventi', async (input, {rejectWithValue, getState}) => {
    try {
        console.log("editEventState input",input)
        let currentState = getState().events.events.map((item) => ({
            ...item,
        }))
        const response = await CalendarService.editEvent(input.id, {
            "data":{
                "stato":input.state
            }
        });
        try{
            if(input.tipo!=="nota" && input.patient!==undefined && input.patient!==null){
                //AGGIORNO CRONOLOGIA
                const patientId = input.patient.split("-")[0].trim();
                console.log("patientId",patientId)
                const cronologiaList = await SchedaPazienteService.getCronologia(patientId)
                console.log("cronologiaList",cronologiaList)
                let index = cronologiaList.data.attributes.cronologia.findIndex(cronologia => cronologia.id === input.id && cronologia.tipologia === "Appuntamento");
                console.log("index",index)
                if(index>=0){
                    let updatedData = [...cronologiaList.data.attributes.cronologia]
                    //COSTRUISCO NUOVA CRONOLOGIA
                    let cronologia = {}
                    cronologia.data = cronologiaList.data.attributes.cronologia[index].data;
                    cronologia.note = cronologiaList.data.attributes.cronologia[index].note;
                    cronologia.referente = JSON.parse(localStorage.getItem("info_user"))?.username;
                    cronologia.tipologia = "Appuntamento";
                    cronologia.id = cronologiaList.data.attributes.cronologia[index].id;
                    cronologia.stato = input.state;

                    //AGGIORNO CRONOLGOIA ATTUALE
                    updatedData[index] = cronologia;
                    //COSTRUISCO BODY RICHIESTA
                    let body = {
                        "data": {
                            "cronologia": updatedData
                        }
                    }
                    //EFFETTUO CHIAMATA
                    await SchedaPazienteService.aggiornaCronologia(patientId, body);
                    await store.dispatch(editProssimoApputamento({id:patientId,cronologia:body.data.cronologia}))
                }
            }
        }
        catch (e) {
            console.error(e)
        }
        const indexobj = currentState.findIndex((element) => element.id === input.id);
        currentState[indexobj] = setEvent(response.data);
        return currentState
    } catch (err) {
        console.error("err", err)
        let message = (
            <div>
                Non è stato possibile modificare l'evento.
                <br/>
                Se il problema persiste, contattare l' assistenza.
            </div>
        );

        errorMessage(message)
        return rejectWithValue('ERRORE')
    }
});

export const deleteEvent = createAsyncThunk('elimina-evento', async (input, {rejectWithValue, getState}) => {
    try {

        //EVENTO
        let id = input.id;
        let currentState = getState().events.events.map((item) => ({
            ...item,
        }))
        await CalendarService.deleteEvent(id);



        //CRONOLOGIA (nota -> faccio il try catch perchè non potrebbe esistere il paziente e quindi crashava l'applicazione
        try {
            if (input.tipo !== "nota") {

                let patientId = input.pazientiLabel.split('-')[0].trim(); // Dividi la stringa usando il trattino come delimitatore e prendi il primo elemento
                const cronologiaList = await SchedaPazienteService.getCronologia(patientId)
                let index = cronologiaList.data.attributes.cronologia.findIndex(cronologia => cronologia.id === input.id && cronologia.tipologia === "Appuntamento");

                //aggiornamento
                let updatedData = [...cronologiaList.data.attributes.cronologia];
                let cronologia = {...updatedData[index]}; // Copia dell'oggetto
                cronologia.stato = "annullato";
                updatedData[index] = cronologia;

                //COSTRUISCO BODY RICHIESTA
                let body = {
                    "data": {
                        "cronologia": updatedData
                    }
                }
                //EFFETTUO CHIAMATA
                await SchedaPazienteService.aggiornaCronologia(patientId, body);
                await store.dispatch(editProssimoApputamento({id:patientId,cronologia:body.data.cronologia}))
            }
        }catch (e) {
            console.error(e)
        }

        const indexobj = currentState.findIndex((element) => element.id === id);
        currentState.splice(indexobj, 1)
        return currentState

    } catch (err) {
        console.error("err", err)
        let message = (
            <div>
                Non è stato possibile modificare l'evento.
                <br/>
                Se il problema persiste, contattare l' assistenza.
            </div>
        );

        errorMessage(message)
        return rejectWithValue('ERRORE')
    }
});

export const setIndexStart = createAction('setIndexStart');
export const setIndexEnd = createAction('setIndexEnd');

//funzioni locali
const setEvent = (event) => {
    return {
        id: event.id,
        start: new Date(event.attributes.start),
        end: new Date(event.attributes.end),
        title: event.attributes.nome_appuntamento,
        note: event.attributes.note,
        stato: event.attributes.stato,
        tipo: event.attributes.tipo,
        pazientiLabel: event.attributes.pazientiLabel,
        store: event.attributes.storeLabel,
        color: "#" + event.attributes.colore,
        operatore: event.attributes.operatoreLabel,
        //color:"#"+event.attributes.operatore.data.attributes.color,
        //operatore:event.attributes.operatore.data.id,
        //store:event.attributes.store.data.id,
    }
}
export const CalendarSlice = createSlice({
    name: 'events',
    initialState,
    reducers: {
        resetState: (state, action) => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadEvents.pending, (state) => {
                state.loadingEvents = true
                state.loadedEvents = false
                state.errorEvents = false
            })
            .addCase(loadEvents.fulfilled, (state, action) => {
                state.loadingEvents = false
                state.events = action.payload
                state.loadedEvents = true
                state.errorEvents = false
            })
            .addCase(loadEvents.rejected, (state, action) => {
                state.loadingEvents = false
                state.events = []
                state.loadedEvents = true
                state.errorEvents = true
            })
            .addCase(addNewEvent.pending, (state) => {
                state.loadingAddEditEvents = true
                state.loadedAddEditEvents = false
                state.errorAddEditEvents = false
            })
            .addCase(addNewEvent.fulfilled, (state, action) => {
                state.events = action.payload
                state.loadingAddEditEvents = false
                state.loadedAddEditEvents = true
                state.errorAddEditEvents = false
            })
            .addCase(addNewEvent.rejected, (state, action) => {
                state.loadingAddEditEvents = false
                state.loadedAddEditEvents = true
                state.errorAddEditEvents = true
            })
            .addCase(editEvent.pending, (state) => {
                state.loadingAddEditEvents = true
                state.loadedAddEditEvents = false
                state.errorAddEditEvents = false
            })
            .addCase(editEvent.fulfilled, (state, action) => {
                state.events = action.payload
                state.loadingAddEditEvents = false
                state.loadedAddEditEvents = true
                state.errorAddEditEvents = false
            })
            .addCase(editEvent.rejected, (state, action) => {
                state.loadingAddEditEvents = false
                state.loadedAddEditEvents = true
                state.errorAddEditEvents = true
            })
            .addCase(editEventState.pending, (state) => {
                state.loadingAddEditEvents = true
                state.loadedAddEditEvents = false
                state.errorAddEditEvents = false
            })
            .addCase(editEventState.fulfilled, (state, action) => {
                state.events = action.payload
                state.loadingAddEditEvents = false
                state.loadedAddEditEvents = true
                state.errorAddEditEvents = false
            })
            .addCase(editEventState.rejected, (state, action) => {
                state.loadingAddEditEvents = false
                state.loadedAddEditEvents = true
                state.errorAddEditEvents = true
            })
            .addCase(deleteEvent.pending, (state) => {
                state.loadingAddEditEvents = true
                state.loadedAddEditEvents = false
                state.errorAddEditEvents = false
            })
            .addCase(deleteEvent.fulfilled, (state, action) => {
                state.events = action.payload
                state.loadingAddEditEvents = false
                state.loadedAddEditEvents = true
                state.errorAddEditEvents = false
            })
            .addCase(deleteEvent.rejected, (state, action) => {
                state.loadingAddEditEvents = false
                state.loadedAddEditEvents = true
                state.errorAddEditEvents = true
            })
            .addCase(setIndexStart, (state, action) => {
                state.indexStart = action.payload;
            })
            .addCase(setIndexEnd, (state, action) => {
                state.indexEnd = action.payload;
            });


    }
})


export default CalendarSlice.reducer;