import fetch from 'auth/GenericFetchInterceptor'

const PuntiVenditaService = {}

PuntiVenditaService.getAll = function (queryString) {
    return fetch({
        url: `api/stores?${queryString}`,
        method: 'GET'
    })
}

PuntiVenditaService.checkPuntoVenditaHasPatients = function (id) {
    return fetch({
        url: `/api/patients?populate=*&filters[stores][id]=${id}`,
        method: 'GET'
    })
}

PuntiVenditaService.getAllNoBinding = function (queryString) {
    return fetch({
        url: `api/stores?sort=id:ASC`,
        method: 'GET'
    })
}

PuntiVenditaService.cratePuntoVendita = function (data) {
    return fetch({
        url: `api/stores`,
        method: 'POST',
        data: data
    })
}


PuntiVenditaService.updatePuntoVendita = function (id, data) {
    return fetch({
        url: `api/stores/${id}`,
        method: 'PUT',
        data: data
    })
}


PuntiVenditaService.deletePuntoVendita = function (id) {
    return fetch({
        url: `api/stores/${id}`,
        method: 'DELETE'
    })
}

export default PuntiVenditaService;